<template>
    <div class="handover-container">
        <section class="table-search">
            <div class="search-L">
                <el-date-picker v-model="dateToDate" type="daterange" align="center" unlink-panels range-separator="至"
                    start-placeholder="交接日期" end-placeholder="交接日期" @change="dateChange" clearable
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </div>
            <div class="search-R">
                <el-button type="primary" v-has="'staff_add'" @click="add('form-ref')">新建交接</el-button>
            </div>
        </section>
        <Table :tableData="tableData" :column="workHandoverColumns" border :headerCellStyle="headerCellStyle">
        </Table>
        <div class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </div>
        <!-- 编辑弹框 -->
        <el-dialog :visible.sync="editShow" :title="editTitle" :close-on-click-modal="false" width="80%" center
            @close="reset">
            <el-form :model="formData" :rules="rules" ref="form-ref" label-width="120px">
                <div style="display: flex;justify-content: space-between;width: 80%;">
                    <el-form-item label="选择原接管人" prop="orgUserId">
                        <el-cascader v-model="formData.orgUserId" ref="orgUserRef" :show-all-levels="false"
                            :options="deptUserList" placeholder="选择原接管人" :props="cascaderProps"
                            @change="orgHandleChange">
                        </el-cascader>
                    </el-form-item>
                    <el-form-item prop="newUserId" label="选择现接管人">
                        <el-cascader v-model="formData.newUserId" :show-all-levels="false" :options="deptUserList"
                            ref="newUserRef" placeholder="选择现接管人" :props="cascaderProps" @change="newHandleChange">
                        </el-cascader>
                    </el-form-item>
                </div>
                <el-form-item label="交接备注" prop="remark">
                    <el-input type="textarea" v-model="formData.remark" maxlength="200"
                        :autosize="{ minRows: 6, maxRows: 10 }" placeholder="请输入200字以内备注内容"></el-input>
                </el-form-item>
                <div class="line"></div>
                <p style="margin: 10px 0;font-weight: 600;color: black;">请勾选要交接的工单</p>
                <Table :tableData="orgUserWorkSheetList" :column="orgUserWorkSheetColumns" border isSelection
                    :headerCellStyle="headerCellStyle" :height="300" @selectionChange="handleSelectionChange">
                    <template slot-scope="scope" slot="remarkSlot">
                        <template
                            v-if="scope.row.approveFormValueVoList && scope.row.approveFormValueVoList.length > 0">
                            <div class="remark" v-for="(item, index) in scope.row.approveFormValueVoList" :key="index">
                                <template v-if="item.inputType == 7">
                                    <span>{{ item.formItemName }}:</span>
                                    <img class="item-img" v-for="(v, k) in item.formItemValue.split(',')" :key="k"
                                        :src="v">
                                </template>
                                <template v-else-if="item.inputType == 8">
                                    <div style="display: flex;align-items: center;">
                                        <span>{{ item.formItemName }}:</span>
                                        <i @click.stop="linkTo(item.formItemValue)" class="el-icon-document"
                                            style="font-size:30px;margin-left:10px;cursor: pointer;"></i>
                                    </div>
                                </template>
                                <template v-else>
                                    <span>{{ item.formItemName }}:</span>
                                    <span>{{ item.formItemValue }}</span>
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            <div style="text-align: center;">-</div>
                        </template>
                    </template>
                </Table>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSubmit('form-ref')">{{ `已选（${formData.instanceIds.length}）立即交接`
                    }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { workHandoverColumns, orgUserWorkSheetColumns } from "@/db/tableColumns";
import { pageList, addHandleOver, workSheetList, userList } from "@/api/examine/handover";
export default {
    data() {
        return {
            myValue: ['2', '3'],
            workHandoverColumns,
            orgUserWorkSheetColumns,
            headerCellStyle: {
                background: '#FAFAFA',
                color: '#000000',
            },
            cascaderProps: {
                value: 'userId',
                label: 'userName',
                children: 'userDeptVoList',
                emitPath: false,
            },

            rules: {
                userName: [{ required: true, message: "请填写姓名", trigger: "blur", }],
                mobile: [
                    { required: true, message: "请填写联系方式", trigger: "blur", },
                    { min: 11, max: 11, essage: "请填写正确的手机号（长度为11位）", trigger: "blur", },
                ],
                deptIds: [{ required: true, message: '请选择部门', trigger: 'change' }]
            },

            // table列表
            currentPage: 1, // 当前页
            total: 0, // 总条数  
            tableData: [],
            tableParams: {
                page: 1,
                pageSize: 10,
                kw: "",
                startDate: "",
                endDate: "",
            },
            // 表单
            formData: {
                orgUserId: "", //原接管人
                newUserId: "", //现接管人
                remark: "", //备注
                instanceIds: [],//要交接的工单列表
            },
            editShow: false, //弹框开关
            editTitle: "", //弹框姓名
            dateToDate: [], //日期范围
            deptUserList: [], //用户列表
            orgUserWorkSheetList: [], //用户工作列表
            rules: {
                orgUserId: [{ required: true, message: "请选择原接管人", trigger: "change", }],
                newUserId: [{ required: true, message: "请选择现接管人", trigger: "change", }],
                remark: [{ required: true, message: "请填写交接备注", trigger: "blur", }],
            },
            multipleSelection: []

        };
    },
    created() {
        this.getTableList();
        this.getDeptUserList();
    },
    mounted() {
    },
    methods: {

        // 查询当前部门的用户列表
        getDeptUserList() {
            userList().then(res => {
                let arr = []
                res.data.forEach(item => {
                    arr.push({
                        userId: item.deptId,
                        userName: item.deptName,
                        userDeptVoList: item.userDeptVoList,
                    })
                })
                this.deptUserList = arr;
            })
        },

        // 【请求】用户列表
        getTableList() {
            pageList(this.tableParams).then((res) => {
                this.tableData = res.data.list;
                this.total = res.data.total;
            });
        },
        // 新建交接
        add(formName) {
            this.editShow = true;
        },
        reset() {
            this.formData = {
                orgUserId: "", //原接管人
                newUserId: "", //现接管人
                remark: "", //备注
                instanceIds: [],//要交接的工单列表
            }
            this.orgUserWorkSheetList = [];
            this.$refs['form-ref'].clearValidate();
        },
        // 表格多选
        handleSelectionChange(val) {
            this.formData.instanceIds = val.map(item => item.sheetInstanceId)
        },

        // 【监听】表格模糊查询
        kwChange: _.debounce(function () {
            this.reload();
        }, 500),

        // 【监听】表格重载
        reload() {
            this.tableParams.page = 1;
            this.currentPage = 1;
            this.getTableList();
        },

        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.getTableList();
        },

        // 【监听】表格条数点击
        onSizeChange(size) {
            this.tableParams.pageSize = size;
            this.reload();
        },

        // 【监听】表单提交
        onSubmit: _.debounce(function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.formData.instanceIds.length == 0) {
                        this.$message.error('至少选择一个工单');
                        return false;
                    }
                    this.addHandleOver()
                } else {
                    return false;
                }
            });

        }, 500),

        dateChange(date) {
            if (date) {
                this.tableParams.startDate = date[0];
                this.tableParams.endDate = date[1];
            } else {
                this.tableParams.startDate = '';
                this.tableParams.endDate = '';
            }

            this.reload();
        },
        orgHandleChange(value) {
            const obj = this.$refs.orgUserRef.getCheckedNodes()[0].data
            this.formData.instanceIds = []
            this.formData.orgDeptId = obj.deptId
            this.getOrgUserWorkSheet(obj.deptId, obj.userId)
        },
        newHandleChange(value) {
            const obj = this.$refs.newUserRef.getCheckedNodes()[0].data
            this.formData.newDeptId = obj.deptId
        },

        // 查询原接管人的工单
        getOrgUserWorkSheet(deptId, userId) {
            let params = {
                orgUserId: userId,
                orgDeptId: deptId,
            }
            workSheetList(params).then(res => {
                this.orgUserWorkSheetList = res.data
            })
        },
        // 新增交接接口
        addHandleOver() {
            addHandleOver(this.formData).then(res => {
                if (res.isSuccess == 'yes') {
                    this.$message.success('交接成功');
                    this.reset()
                    this.editShow = false;
                    this.reload();
                }
            })
        },




    },
};
</script>

<style lang="scss" scoped>
.handover-container {
    padding: .2rem;
}

.table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: .15rem;
}

.line {
    width: 100%;
    height: 1px;
    background: #E5E5E5;
    margin: .15rem 0;

}

.table-footer {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;

}

.search-L {
    display: flex;
}
</style>