// 工单交接相关接口
import request from "@/utils/request";
// 获取列表
export function pageList(data) {
  return request("post", "/apm/workSheet/handover/page", data);
}
// 新增交接
export function addHandleOver(data) {
  return request("post", "/apm/workSheet/handover/add", data);
}
// 查询当前用户所在部门工单
export function workSheetList(data) {
  return request("post", "/apm/workSheet/handover/workSheet/List", data);
}
// 查询部门用户
export function userList(data) {
  return request("post", "/apm/workSheet/handover/getUserDeptVo", data);
}
